export class AccountsTransactionModel
{
    ID:number=0;
    TrxNumber: string ="";
    TransType : string ="";
    RelatedTransNo: number = 0;
    SourceAccountID: number = 0;
    PaymentMethod: number = 0;
    DepartmentID: number = 0;
    CurrencyID: number = 0;
    CurrencyRate: string ="";
    BaseCurrencyAmt: string ="";
    AmountTax: number = 0;
    TDate: string = "";
    Rederence : string ="";
    Chq_TrfNo : string ="";
    ProjectID : number=0;
    Narrations : string ="";
    Notes : string ="";
    Status : string ="";
    IsApproved : number=0;
    ApprovedBy : number=0;
    ApprovedOn : string="";
    CreatedBy: number = 0;
    CreatedOn: string = "";
    ModifiedBy: number = 0;
    ModifiedOn: string = "";
}