import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/throw';
import { CustomFieldsModulesScreensModel } from '../models/CustomFieldsModulesScreens.Model';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldsModulesScreensService {

  constructor(private http: HttpClient) { }

  objCustomFieldsModulesScreensModel: CustomFieldsModulesScreensModel ;
  objCustomFieldsModulesScreensModelList: CustomFieldsModulesScreensModel[];

  //readonly apiURL = "http://localhost:2130/api/CustomFieldsModulesScreen";
  readonly apiURL = "http://apiwrdserp.widerangedigital.com/api/CustomFieldsModulesScreen";


  Insert(obj: CustomFieldsModulesScreensModel) {
    obj.ID = 0;
    obj.Active =1;
    obj.CreatedBy = 1; // need to update
    obj.CreatedOn = new Date().toDateString();
    obj.ModifiedBy= 1; // need to update
    obj.ModifiedOn = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveCustomFieldsModulesScreens", obj);
  }
  Update(obj: CustomFieldsModulesScreensModel) {
    obj.Active =1;
    obj.ModifiedBy= 1; // need to update
    obj.ModifiedOn  = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveCustomFieldsModulesScreens/" + obj.ID, obj);
  }
  GetAll() {
    return this.http.get(this.apiURL + "/GetCustomFieldsModulesScreens").map(res => this.objCustomFieldsModulesScreensModelList = res as CustomFieldsModulesScreensModel[]);
  }
  Delete(ID: number) {
    return this.http.post(this.apiURL + "/DeleteCustomFieldsModulesScreens/" + ID, ID);
  }
  ClearList() {
    this.objCustomFieldsModulesScreensModelList = [];
  }
  Clear() {
    this.objCustomFieldsModulesScreensModel = new CustomFieldsModulesScreensModel();
  }
}
