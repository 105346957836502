import { Injectable } from '@angular/core';
import { CurrencyModel } from '../Models/currency.model';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private http: HttpClient ) { }
  formData: CurrencyModel;
  currencyList: CurrencyModel[];
  
  readonly apiURL = "http://apiwrdserp.widerangedigital.com/api";

  getAllRecords() {
    return this.http.get(this.apiURL + "/SettingCurrency/GetCurrency").map(res => this.currencyList = res as CurrencyModel[]);
  }

  //getRecords
  getCurrency() {
    this.http.get(this.apiURL + '/SettingCurrency/GetCurrency').toPromise().then(res => this.currencyList = res as CurrencyModel[]);
  }


  //insert Data in Db
  insertCurrency(formData: CurrencyModel) {
    console.log(formData);
    formData.ID = 0;
    formData.Active = 1;
    //formData.CreatedBy = 1; //need to update;
    //formData.CreatedOn = new Date().toDateString();
    //formData.ModifiedBy = 1; //need to update;
    formData.ModifiedOn =new Date().toDateString();
    return this.http.post(this.apiURL + "/SettingCurrency/SaveCurrency", formData);
  }

  //update Data in Db
  updateCurrency(formData: CurrencyModel) {
    formData.Active = 1;
    //formData.ModifiedBy = 1; //need to update;
    formData.ModifiedOn =new Date().toDateString();
    console.log("Update :", formData);
    return this.http.post(this.apiURL + "/SettingCurrency/SaveCurrency", formData);
  }

  //Delete Data 
  deleteCurrency(id: number) {
    return this.http.post(this.apiURL + '/SettingCurrency/DeleteCurrency/' + id, id);
  }


}
