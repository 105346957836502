import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/throw';
import { AccountsTaxesModel } from '../Models/AccountsTaxes.model';



@Injectable({
  providedIn: 'root'
})
export class AccountsTaxesService {

  constructor(private http: HttpClient) { }
  objAccTaxes: AccountsTaxesModel;
  objAccTaxesList: AccountsTaxesModel[];

  readonly apiURL = "http://apiwrdserp.widerangedigital.com/api/AccountsTaxes";

  Insert(obj: AccountsTaxesModel) {
    console.log('OBJECT : ', obj);
   
    // obj.TaxRatePercentage=txPerc;
    obj.ID = 0;
    obj.Active =1;
    return this.http.post(this.apiURL + "/SaveAccountsTax", obj);
  }


  Update(obj: AccountsTaxesModel) {
    obj.Active =1;
    obj.ModifiedBy = 1; // need to update
    obj.ModifiedOn = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveAccountsTax/" + obj.ID, obj);
  }
  GetAll() {
    //console.log('test data : ',this.http.get(this.apiURL + "/GetCompanies").map(res => this.objCompanyList = res as CompanyModel[]));
    return this.http.get(this.apiURL + "/GetAccountsTaxes").map(res => this.objAccTaxesList = res as AccountsTaxesModel[]);
  }

  Delete(ID: number) {
    //console.log('ID : ', ID);
    return this.http.post(this.apiURL + "/DeleteAccountsTax/" + ID, ID);
  }
  ClearList() {
    this.objAccTaxesList = [];
  }
  Clear() {
    this.objAccTaxes = new AccountsTaxesModel();
  }





}
