export class AccountsTaxesModel {
    ID: number = 0;
    TaxName:string="";
    TaxType	:string="";
    TaxRatePercentage:string="";   //decimal 
    Active: number = 0;
    IsDeleted:number=0;
    CreatedBy: number = 0;
    CreatedOn: string = "";
    ModifiedBy: number = 0;
    ModifiedOn: string = "";
}