import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/throw';
import { CustomFieldsVisibleToModel } from '../models/CustomFieldsVisibleTo.Model';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldsVisibleToService {

  constructor(private http: HttpClient) { }

  objCustomFieldsVisibleToModel: CustomFieldsVisibleToModel ;
  objCustomFieldsVisibleToModelList: CustomFieldsVisibleToModel[];

  //readonly apiURL = "http://localhost:2130/api/CustomFieldsVisibleTo";
  readonly apiURL = "http://apiwrdserp.widerangedigital.com/api/CustomFieldsVisibleTo";

        /* Testing Point */
  Insert(obj: CustomFieldsVisibleToModel) {
    obj.ID = 0;
    obj.Active =1;
    obj.CreatedBy = 1; // need to update
    obj.CreatedOn = new Date().toDateString();
    obj.ModifiedBy= 1; // need to update
    obj.ModifiedOn = new Date().toDateString();
    console.log("Visible to Values : ", obj);
    return this.http.post(this.apiURL + "/SaveCustomFieldsVisibleTo", obj);
  }

  InsertList(obj: Array<CustomFieldsVisibleToModel>) {
    // obj.ID = 0;
    // obj.Active =1;
    // obj.CreatedBy = 1; // need to update
    // obj.CreatedOn = new Date().toDateString();
    // obj.ModifiedBy= 1; // need to update
    // obj.ModifiedOn = new Date().toDateString();
    console.log("Visible to Values : ", obj);
    return this.http.post(this.apiURL + "/SaveCustomFieldsVisibleToList", obj).subscribe(res=>{
      console.log("Custom Field Visible to Services:");
    });
  }







  Update(obj: CustomFieldsVisibleToModel) {
    obj.Active =1;
    obj.ModifiedBy= 1; // need to update
    obj.ModifiedOn  = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveCustomFieldsVisibleTo/" + obj.ID, obj);
  }
  GetAll() {
    return this.http.get(this.apiURL + "/GetCustomFieldsVisibleTo").map(res => this.objCustomFieldsVisibleToModelList = res as CustomFieldsVisibleToModel[]);
  }

  GetAllbyFieldID(fieldID: number){
    return this.http.get(this.apiURL + "/GetCustomFieldsVisibleToByFieldID?fieldID=" + fieldID).map(res => this.objCustomFieldsVisibleToModelList = res as CustomFieldsVisibleToModel[]);
  }


  Delete(ID: number) {
    return this.http.post(this.apiURL + "/DeleteCustomFieldsVisibleTo/" + ID, ID);
  }
  ClearList() {
    this.objCustomFieldsVisibleToModelList = [];
  }
  Clear() {
    this.objCustomFieldsVisibleToModel = new CustomFieldsVisibleToModel();
  }
}
