import { Injectable } from '@angular/core';
import { OrganizationModel } from '../Models/Organization.Model';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/throw';
@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private http: HttpClient) { }

  formData: OrganizationModel;
  orgList: OrganizationModel[];

  // readonly apiURL = "http://192.168.0.121/ERP/api/Organization";
  readonly apiURL = "http://apiwrdserp.widerangedigital.com/api/Organization";


  saveRecord(formData: OrganizationModel){

    formData.ID = 0;
    formData.Active = 1;
    formData.CreatedBy = 1; //need to update;
    //formData.CreatedOn = new Date().getDate().toString();
    formData.ModifiedBy = 1; //need to update;
    //formData.ModifiedOn = Date.now().toString();
    return this.http.post(this.apiURL + "/SaveOrganization", formData);
  }
  updateRecord(formData: OrganizationModel){
    formData.Active = 1;
    formData.ModifiedBy = 1; //need to update;
    //formData.ModifiedOn = Date.now().toString();
    return this.http.post(this.apiURL + "/SaveOrganization/" + formData.ID, formData);
  }
  getItems(){
    this.http.get(this.apiURL + "/GetOrganization").toPromise().then( res => this.orgList = res as OrganizationModel[]);
  }
  getAllRecords(){
    return this.http.get(this.apiURL + "/GetOrganization").map(res => this.orgList = res as OrganizationModel[]);
  }
  deleteItem(ID: number){
    //alert("this is service path : " + this.apiURL + "/OrganizationDelete/" + ID);
    //console.log("Delete Service Path " + this.apiURL + "/DeleteOrganization/" +ID);
    return this.http.post(this.apiURL + "/DeleteOrganization/" +ID , ID);
  }

}
