export class COAModel {
    ID: number = 0;
    AccountTypeID: number = null;
    AccountType : string = "";
    AccountCode: string = "";
    AccountName: string = "";
    TAX_Rate: string = "";
    Des: string = "";
    ShowonExpence : number=0;
    EnablePayments : number=0;
    OpeningBalance: string ="";
    OpeningBalanceDate: string ="";
    CurrentBalance : string="";
    LastModification:string="";
    Active: number = 0;
    CreatedBy: number = 0;
    CreatedOn: string = "";
    ModifiedBy: number = 0;
    ModifiedOn: string = "";
}