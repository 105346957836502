export class CompanyModel {
    ID: number = 0;
    OrgID: number = 0;
    CompanyCode: string = "";
    CompanyName: string = "";
    RegNo: string = "";
    NoOfEmployees: string = "";
    RegDate: string = "";
    OfficeNumber: string = "";
    MobileNumber: string = "";
    FaxNumber: string = "";
    EmailID: string = "";
    Website: string = "";
    Language: string = "";
    WeekStartDay: string = "";
    CompanyBCCEmail: string = "";
    CompanyTimeZone: string = "";
    ShortDateFormat: string = "";
    LongDateFormat: string = "";
    Logo: string = "";
    PasswordExpiryDays: number = 0;
    Active: number = 0;
    CreatedBy: number = 0;
    CreatedOn: string = "";
    ModifiedBy: number = 0;
    ModifiedOn: string = "";
}