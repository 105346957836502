import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/throw';
import { AccountsAccountTypesModel } from '../Models/AccountsAccountTypes.model';



@Injectable({
  providedIn: 'root'
})
export class AccountsAccountTypesServices {

  constructor(private http: HttpClient) { }
  objAccountsAccountTypes: AccountsAccountTypesModel;
  objAccountsAccountTypesList: AccountsAccountTypesModel[];

  readonly apiURL = "http://apiwrdserp.widerangedigital.com/api/AccountsAccountTypes";

  Insert(obj: AccountsAccountTypesModel) {
    console.log('OBJECT : ', obj);
   
    // obj.TaxRatePercentage=txPerc;
    obj.ID = 0;
    obj.Active =1;
    return this.http.post(this.apiURL + "/SaveAccountType", obj);
  }


  Update(obj: AccountsAccountTypesModel) {
    obj.Active =1;
    obj.ModifiedBy = 1; // need to update
    obj.ModifiedOn = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveAccountType/" + obj.ID, obj);
  }
  GetAll() {
   
    return this.http.get(this.apiURL + "/GetAccountTypes").map(res => this.objAccountsAccountTypesList = res as AccountsAccountTypesModel[]);
  }

  // Delete(ID: number) {
  //   //console.log('ID : ', ID);
  //   return this.http.post(this.apiURL + "/DeleteAccountsTax/" + ID, ID);
  // }

  ClearList() {
    this.objAccountsAccountTypesList = [];
  }
  Clear() {
    this.objAccountsAccountTypes = new AccountsAccountTypesModel();
  }





}
