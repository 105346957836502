import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/throw';
import { CompanyModel } from '../models/Company.model';
@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

  objCompany: CompanyModel;
  objCompanyList: CompanyModel[];

  //readonly apiURL = "http://localhost:2130/api/Companies";
  readonly apiURL = "http://apiwrdserp.widerangedigital.com/api/Companies";


  Insert(obj: CompanyModel) {
    console.log('OBJECT : ', obj);
    obj.ID = 0;
    obj.Active =1;
    return this.http.post(this.apiURL + "/SaveCompany", obj);
  }
  Update(obj: CompanyModel) {
    obj.Active =1;
    return this.http.post(this.apiURL + "/SaveCompany/" + obj.ID, obj);
  }
  GetAll() {

    //console.log('test data : ',this.http.get(this.apiURL + "/GetCompanies").map(res => this.objCompanyList = res as CompanyModel[]));
    return this.http.get(this.apiURL + "/GetCompanies").map(res => this.objCompanyList = res as CompanyModel[]);
  }
  Delete(ID: number) {
    //console.log('ID : ', ID);
    return this.http.post(this.apiURL + "/DeleteCompany/" + ID, ID);
  }
  ClearList() {
    this.objCompanyList = [];
  }
  Clear() {
    this.objCompany = new CompanyModel();
  }
  // private handleError(errorResponse: HttpErrorResponse){
  //   if(errorResponse.error instanceof ErrorEvent){
  //     console.log('Client Side Error', errorResponse.error.message);
  //   }
  //   else{
  //     console.log('Server Side Error', errorResponse);
  //   }
  //   return new ErrorObservable('This is a problem from service. We are notified and working on it. Please try again later.');
  // }
}

