export class CustomFieldsVisibleToModel
{
    ID : number = 0;
    FieldID : number = 0;
    UserGroupID : number = null;
    Active : number = 0;
    IsDeleted : number = 0;
    CreatedBy : number = 0;
    CreatedOn  : string = "";
    ModifiedBy : number = 0;
    ModifiedOn  : string = "";


}