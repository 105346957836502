import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/throw';
import { CustomFieldsModel } from '../models/CustomFields.Model';
@Injectable({
  providedIn: 'root'
})
export class CustomFieldsService {

  constructor(private http: HttpClient) { }

  objCustomFieldsModel: CustomFieldsModel;
  objCustomFieldsModelList: CustomFieldsModel[];

  //readonly apiURL = "http://localhost:2130/api/CustomFields";
  readonly apiURL = "http://apiwrdserp.widerangedigital.com/api/CustomFields";


  Insert(obj: CustomFieldsModel) {
    obj.ID = 0;
    obj.isActive =1;
    obj.CreatedBy = 1; // need to update
    obj.CreatedOn = new Date().toDateString();
    obj.ModifiedBy= 1; // need to update
    obj.ModifiedOn = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveCustomField", obj);
  }
  Update(obj: CustomFieldsModel) {
    obj.isActive =1;
    obj.ModifiedBy= 1; // need to update
    obj.ModifiedOn  = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveCustomField/" + obj.ID, obj);
  }
  GetAll() {
    return this.http.get(this.apiURL + "/GetCustomFields").map(res => this.objCustomFieldsModelList = res as CustomFieldsModel[]);
  }
  Delete(ID: number) {
    return this.http.post(this.apiURL + "/DeleteCustomField/" + ID, ID);
  }
  ClearList() {
    this.objCustomFieldsModelList = [];
  }
  Clear() {
    this.objCustomFieldsModel = new CustomFieldsModel();
  }
}
