import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/throw';
import { CustomFieldsModulesModel } from '../models/CustomFieldsModules.Model';
@Injectable({
  providedIn: 'root'
})
export class CustomFieldsModulesService {

  constructor(private http: HttpClient) { }

  objCustomFieldsModulesModel: CustomFieldsModulesModel ;
  objCustomFieldsModulesModelList: CustomFieldsModulesModel[];

  //readonly apiURL = "http://localhost:2130/api/CustomFieldsModules";
  readonly apiURL = "http://apiwrdserp.widerangedigital.com/api/CustomFieldsModules";


  Insert(obj: CustomFieldsModulesModel) {
    obj.ID = 0;
    obj.Active =1;
    obj.CreatedBy = 1; // need to update
    obj.CreatedOn = new Date().toDateString();
    obj.ModifiedBy= 1; // need to update
    obj.ModifiedOn = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveCustomFieldsModules", obj);
  }
  Update(obj: CustomFieldsModulesModel) {
    obj.Active =1;
    obj.ModifiedBy= 1; // need to update
    //console.log("Update value : " + obj);
    obj.ModifiedOn  = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveCustomFieldsModules/" + obj.ID, obj);
    
  }
  GetAll() {
    return this.http.get(this.apiURL + "/GetCustomFieldsModules").map(res => this.objCustomFieldsModulesModelList = res as CustomFieldsModulesModel[]);
  }
  Delete(ID: number) {
    return this.http.post(this.apiURL + "/DeleteCustomFieldsModules/" + ID, ID);
  }
  ClearList() {
    this.objCustomFieldsModulesModelList = [];
  }
  Clear() {
    this.objCustomFieldsModulesModel = new CustomFieldsModulesModel();
  }
}
