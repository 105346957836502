import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParentAccountTypeModel } from '../Models/ParentAccountType.model'

@Injectable({
  providedIn: 'root'
})
export class ParentAccountTypeService {
  
  constructor(private http: HttpClient) { }

  objParentAccType: ParentAccountTypeModel;
  objParentAccTypeList: ParentAccountTypeModel[];


  readonly apiURL = "http://apiwrdserp.widerangedigital.com/api/AccountsParentAccountTypes";


  Insert(obj: ParentAccountTypeModel) {
    console.log('OBJECT : ', obj);
    obj.ID = 0;
    return this.http.post(this.apiURL + "/SaveParentAccountType", obj);
  }
  Update(obj: ParentAccountTypeModel) {
        
    return this.http.post(this.apiURL + "/SaveParentAccountType/" + obj.ID, obj);
  }
  GetAll() {

    //console.log('test data : ',this.http.get(this.apiURL + "/GetCompanies").map(res => this.objCompanyList = res as CompanyModel[]));
    return this.http.get(this.apiURL + "/GetParentAccountTypes").map(res => this.objParentAccTypeList = res as ParentAccountTypeModel[]);
  }
  // Delete(ID: number) {
  //   //console.log('ID : ', ID);
  //   return this.http.post(this.apiURL + "/DeleteCompany/" + ID, ID);
  // }
  ClearList() {
    this.objParentAccTypeList = [];
  }
  Clear() {
    this.objParentAccType = new ParentAccountTypeModel();
  }


}

