export class CustomFieldsModel
{
    ID : number = 0;
    ModuleScreenID : number = 0;
    FieldName  : string = "";
    AllasName  : string = "";
    DataTypes  : string = "";
    FieldType  : string = "";
    isRequired : number = 0;
    isShowInListing : number = 0;
    isShowInSorting : number = 0;
    isActive : number = 0;
    isDeleted : number = 0;
    CreatedBy : number = 0;
    CreatedOn  : string = "";
    ModifiedBy : number = 0;
    ModifiedOn  : string = "";
}