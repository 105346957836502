import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/throw';
import { AccountsTransactionModel } from '../Models/AccountsTransaction.model';



@Injectable({
  providedIn: 'root'
})
export class AccountTransactionsService {

  constructor(private http: HttpClient) { }
  objAccountsAccountsTransaction: AccountsTransactionModel;
  objAccountsAccountsTransactionList: AccountsTransactionModel[];

  readonly apiURL = "http://apiwrdserp.widerangedigital.com/api/AccountsAccountTransactions";

  Insert(obj: AccountsTransactionModel) {
    console.log('OBJECT : ', obj);
   
    // obj.TaxRatePercentage=txPerc;
    obj.ID = 0;
    return this.http.post(this.apiURL + "/SaveAccountsTransactions", obj);
  }


  Update(obj: AccountsTransactionModel) {
    obj.ModifiedBy = 1; // need to update
    obj.ModifiedOn = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveAccountsTransactions/" + obj.ID, obj);
  }
  GetAll() {
   
    return this.http.get(this.apiURL + "/GetAccountsTransactions").map(res => this.objAccountsAccountsTransactionList = res as AccountsTransactionModel[]);
  }

  // Delete(ID: number) {
  //   //console.log('ID : ', ID);
  //   return this.http.post(this.apiURL + "/DeleteAccountsTax/" + ID, ID);
  // }

  ClearList() {
    this.objAccountsAccountsTransactionList = [];
  }
  Clear() {
    this.objAccountsAccountsTransaction = new AccountsTransactionModel();
  }





}
