export class CustomFieldsFieldDataModel
{
    ID : number = 0;
    FieldID : number = 0;
    ValueID :string=null;
    ValueText : string ;
    SortOrder : number = 0;
    Active : number = 0;
    IsDeleted : number = 0;
    CreatedBy : number = 0;
    CreatedOn  : string = "";
    ModifiedBy : number = 0;
    ModifiedOn  : string = "";
}