import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/throw';
import { COAModel } from '../Models/coa.model';
@Injectable({
  providedIn: 'root'
})
export class COAService {

  constructor(private http: HttpClient) { }

  objCOA: COAModel;
  objCOAList: COAModel[];

  //readonly apiURL = "http://localhost:2130/api/Companies";
  readonly apiURL = "http://apiwrdserp.widerangedigital.com/api/AccountsAccountCOA";


  Insert(obj: COAModel) {
    console.log('OBJECT : ', obj);
    obj.ID = 0;
    obj.Active = 1;
    obj.CreatedBy = 0;
     obj.CreatedOn = new Date().toDateString();
     obj.ModifiedBy = 0;
     obj.ModifiedOn = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveAccountsCOA", obj);
  }
  Update(obj: COAModel) {
    obj.Active = 1;
    obj.ModifiedBy = 0;
     obj.ModifiedOn = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveAccountsCOA/" + obj.ID, obj);
  }
  GetAll() {
    return this.http.get(this.apiURL + "/GetAccountsCOA").map(res => this.objCOAList = res as COAModel[]);
  }
  Delete(ID: number) {
    //console.log('ID : ', ID);
    return this.http.post(this.apiURL + "/DeleteAccountsCOA/" + ID, ID);
  }
  ClearList() {
    this.objCOAList = [];
  }
  Clear() {
    this.objCOA = new COAModel();
  }
}
