export class AccountsAccountTypesModel
{
    ID:number=0;
    ParentAccountID:number=null;
    ParentAccount:string="";
    AccounTypeCode:string	="";
    AccountType	:string="";
    Des:string="";	
    Active: number = 0;
    IsDeleted: number = 0;
    CreatedBy: number = 0;
    CreatedOn: string = "";
    ModifiedBy: number = 0;
    ModifiedOn: string = "";
}