import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberDirective } from './numbers-only.directive';
import { TwoDigitDecimaNumberDirective } from './two-digit-decimal-num.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [NumberDirective,TwoDigitDecimaNumberDirective],
  exports: [
    NumberDirective,
    TwoDigitDecimaNumberDirective
  ]
})
export class DirectivesModule { }
