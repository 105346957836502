export class CustomFieldsModulesScreensModel
{
    ID : number = 0;
    ModuleID : number = 0;
    Process_ScreenName  : string = "";
    CoreTable_Name  : string = "";
    Active : number = 0;
    IsDeleted : number = 0;
    CreatedBy : number = 0;
    CreatedOn  : string = "";
    ModifiedBy : number = 0;
    ModifiedOn  : string = "";
}