import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/throw';
import { CustomFieldsFieldDataModel } from '../models/CustomFieldsFieldData.Model';
@Injectable({
  providedIn: 'root'
})
export class customFieldsFieldDataService {

  constructor(private http: HttpClient) { }

  objCustomFieldsFieldDataModel: CustomFieldsFieldDataModel;
  objCustomFieldsFieldDataModelList: CustomFieldsFieldDataModel[];

  //readonly apiURL = "http://localhost:2130/api/CustomFieldsFieldData";
  readonly apiURL = "http://apiwrdserp.widerangedigital.com/api/CustomFieldsFieldData";


  Insert(obj: CustomFieldsFieldDataModel) {
    obj.ID = 0;
    obj.Active =1;
    obj.CreatedBy = 1; // need to update
    obj.CreatedOn = new Date().toDateString();
    obj.ModifiedBy= 1; // need to update
    obj.ModifiedOn = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveCustomFieldsFieldData", obj);
  }

  
  InsertList(obj: CustomFieldsFieldDataModel[]) {
    
    return this.http.post(this.apiURL + "/SaveCustomFieldsFieldDataList", obj).subscribe(res=>{
      console.log("Success.....",obj);
    });
  }

  // UpdateList(obj: CustomFieldsFieldDataModel) {
  //   return this.http.post(this.apiURL + "/SaveCustomFieldsFieldDataList/" + obj.ID, obj);
  // }
  
  Update(obj: CustomFieldsFieldDataModel) {
    obj.Active =1;
    obj.ModifiedBy= 1; // need to update
    obj.ModifiedOn  = new Date().toDateString();
    return this.http.post(this.apiURL + "/SaveCustomFieldsFieldData/" + obj.ID, obj);
  }
  GetAll() {
    return this.http.get(this.apiURL + "/GetCustomFieldsFieldData").map(res => this.objCustomFieldsFieldDataModelList = res as CustomFieldsFieldDataModel[]);
  }
  Delete(ID: number) {
    return this.http.post(this.apiURL + "/DeleteCustomFieldsFieldData/" + ID, ID);
  }
  ClearList() {
    this.objCustomFieldsFieldDataModelList = [];
  }
  Clear() {
    this.objCustomFieldsFieldDataModel = new CustomFieldsFieldDataModel();
  }
  GetAllbyFieldID(fieldID: number){
    return this.http.get(this.apiURL + "/GetCustomFieldsFieldDataByFieldID?fieldID=" + fieldID).map(res => this.objCustomFieldsFieldDataModelList = res as CustomFieldsFieldDataModel[]);
  }
}
